var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var constants;

(function (constants) {
  constants.typeOfFunction = "function";
  constants.boolTrue = true;
})(constants || (constants = {}));

function bind(target, propertyKey, descriptor) {
  if (!descriptor || typeof descriptor.value !== constants.typeOfFunction) {
    throw new TypeError("Only methods can be decorated with @bind. <" + propertyKey + "> is not a method!");
  }

  return {
    configurable: constants.boolTrue,
    get: function () {
      var bound = descriptor.value.bind(this); // Credits to https://github.com/andreypopp/autobind-decorator for memoizing the result of bind against a symbol on the instance.

      Object.defineProperty(this, propertyKey, {
        value: bound,
        configurable: constants.boolTrue,
        writable: constants.boolTrue
      });
      return bound;
    }
  };
}

exports.bind = bind;
exports.default = bind;
export default exports;
export const __esModule = exports.__esModule;
const _bind = exports.bind;
export { _bind as bind };